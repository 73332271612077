import { Partition } from '@snapchat/graphene';

import type { SiteConfiguration } from '../../configTypes';
import { LoggingVendor } from '../../helpers/logging/eventListenerTypes';

export const config: SiteConfiguration = {
  domainName: 'values.snap.com',
  redirects: {
    hostRedirects: {},
  },
  globalNavProps: {
    defaultGroupKey: 'safety',
  },
  trackingSettings: {
    eventListeners: [
      { vendor: LoggingVendor.BLIZZARD },
      { vendor: LoggingVendor.CONSOLE },
      { vendor: LoggingVendor.GOOGLE_CLOUD_LOGGING },
      { vendor: LoggingVendor.GOOGLE_CLOUD_MONITORING },
      { vendor: LoggingVendor.MWP_HERMES },
      { vendor: LoggingVendor.GRAPHENE, partitionName: Partition.TRUST },
      {
        vendor: LoggingVendor.GOOGLE,
        googleTagManagerId: 'GTM-PB72WQ3',
        googleAnalyticsId: 'UA-41740027-102', // should this be deprecated?
      },
      {
        vendor: LoggingVendor.SENTRY,
        projectName: 'trust',
        dsn: 'https://de9181c6836a496da6750fbd8aa8ebe0@sentry.sc-prod.net/129',
      },
    ],
  },
  enabledCdn: true,
};
